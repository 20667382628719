import ControllerBalance from './player/ControllerBalance';
import ControllerBet from './player/ControllerBet';
import ControllerWin from './player/ControllerWin';
import ControllerSpin from './player/ControllerSpin';
import ControllerTotalWin from './player/ControllerTotalWin';
import ControllerBetSelect from './player/ControllerBetSelect';
import ControllerAutoPlayButton from './player/ControllerAutoPlayButton';
import ControllerTurboPlay from './player/ControllerTurboPlay';
import ControllerLabel from './player/ControllerLabel';
import ControllerInfo from './player/ControllerInfo';
import ControllerMenu from './player/ControllerMenu';
import ControllerFullScreen from './player/ControllerFullScreen';
import ControllerSound from './player/ControllerSound';
import ControllerBetSelectorTable from './player/ControllerBetSelectorTable';
import ControllerTournamentsLabel from './player/ControllerTournamentsLabel';
import ControllerAutoPlayCount from './player/ControllerAutoPlayCount';
import ControllerWinLineLabel from './player/ControllerWinLineLabel';
import ControllerFreespinsCount from './player/ControllerFreespinsCount';

import ControllerInfoPanel from './player/infoPanel/ControllerInfoPanel';
import ControllerClock from './player/infoPanel/ControllerClock';

import ControllerHonesty from './system/ControllerHonesty';
import ControllerTournaments from './system/ControllerTournaments';
import ControllerNotifications from './notifications/ControllerNotificationsManager';
import ControllerTournamentsStartScreen from './system/ControllerTournamentsStartScreen';
import ControllerModalTournamentEnd from './system/ControllerModalTournamentEnd';
import ControllerModalTournamentDetails from './system/tournaments/ControllerModalTournamentDetails';
import ControllerSidebar from './system/ControllerSidebar';
import ControllerRoundsHistory from './system/ControllerRoundsHistory';
import ControllerWinLimits from './system/ControllerWinLimits';
import ControllerGameSettings from './system/ControllerGameSettings';
import ControllerBugReport from './system/ControllerBugReport';

export {
  ControllerBalance,
  ControllerBet,
  ControllerWin,
  ControllerSpin,
  ControllerTotalWin,
  ControllerBetSelect,
  ControllerAutoPlayButton,
  ControllerInfo,
  ControllerMenu,
  ControllerFullScreen,
  ControllerSound,
  ControllerBetSelectorTable,
  ControllerFreespinsCount,

  ControllerHonesty,
  ControllerTournaments,
  ControllerTournamentsLabel,
  ControllerNotifications,
  ControllerTournamentsStartScreen,
  ControllerModalTournamentEnd,
  ControllerSidebar,
  ControllerModalTournamentDetails,
  ControllerTurboPlay,
  ControllerLabel,
  ControllerInfoPanel,
  ControllerClock,
  ControllerAutoPlayCount,
  ControllerWinLineLabel,
  ControllerRoundsHistory,
  ControllerWinLimits,
  ControllerGameSettings,
  ControllerBugReport,
};
