import BaseController from './basePlayerController';
import { format } from '../../../utils/formatCurrency';
import { ePlayerUiWinLineTypes } from '../../../enums/playerUiWinLineTypes';

export default class ControllerWinLineLabel extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_VALUE: 'value',
      EET_IMAGE_LIST: 'image_list',
    };
    this._adaptiveElements = [this._eElementsTypes.EET_VALUE];

    this.init(container);
    this.hide();

    /**
     this._winLineConfig = {
     type: ePlayerUiWinLineTypes,
     idToClassNameAdapter: {
     '1': 'wild',
     '2': 'scatter_1',
     '3': 'coin',
     },
     }

     this._winLineData = {
     winLineCoef: 1.25,
     winLineIds: [1,2,3,3,4] | [[1,1,1], [1,1]]  (x-lines/match3 | megaways)
     betAmount: 0.3,
     winAmount: 0.38
     multipliers: [2, 3]
     }
     */
  }

  setWinLineConfig(winLineConfig) {
    this._winLineConfig = winLineConfig;
  }

  setWinLine(winLineData) {
    this._winLineData = winLineData;
    const symbolsMarkup = this._createSymbolsMarkup();
    this.setInnerHTML(this._eElementsTypes.EET_IMAGE_LIST, symbolsMarkup);
    const multipliers = [this._winLineData.winLineCoef, ...(this._winLineData.multipliers || [])];
    const winLineInfo = `${this._moneyFormat(this._winLineData.betAmount)} × ${multipliers.join(' × ')} = ${this._moneyFormat(this._winLineData.winAmount)}`
    this.set(this._eElementsTypes.EET_VALUE, winLineInfo);
  }

  _createSymbolsMarkup() {
    switch (this._winLineConfig.type) {
      case ePlayerUiWinLineTypes.EWLT_X_LINES: return this._createXLinesMarkup();
      case ePlayerUiWinLineTypes.EWLT_MEGAWAYS: return this._createMegawaysMarkup();
      case ePlayerUiWinLineTypes.EWLT_MATCH_3: return this._createMatch3Markup();
      default: return this._createXLinesMarkup();
    }
  }

  _createXLinesMarkup() {
    let ids = this._winLineData.winLineIds;
    const markup = this._winLineData.lineNumber ? `<span>${this._winLineData.lineNumber}</span>` : '';

    return ids.reduce((prev, id) => {
      return prev + `<span class="${this._winLineConfig.idToClassNameAdapter[id]} ui_win_lines__sprite ui-container__win_line_label__image_list__sprite"></span>`
    }, markup);
  }

  _createMegawaysMarkup() {
    const id = this._winLineData.winLineIds[0][0];
    const counts = this._winLineData.winLineIds.map(reel => `×${reel.length}`);

    return `<span class="ui-container__win_line_label__image_list__count">${counts.join(' ')}</span>
<span class="${this._winLineConfig.idToClassNameAdapter[id]} ui_win_lines__sprite ui-container__win_line_label__image_list__sprite"></span>`;
  }

  _createMatch3Markup() {
    const counts = {};
    const ids = this._winLineData.winLineIds.reduce((acc, id) => {
      if (!acc.includes(id)) acc.push(id);
      if (!counts.hasOwnProperty(id)) counts[id] = 0;
      counts[id]++;
      return acc;
    }, []);

    return ids.reduce((prev, id) => {
      if (counts) prev += `<span class="ui-container__win_line_label__image_list__count">${counts[id]} × </span>`
      return prev + `<span class="${this._winLineConfig.idToClassNameAdapter[id]} ui_win_lines__sprite ui-container__win_line_label__image_list__sprite"></span>`
    }, '')
  }

  setValue(value) {
    this.set(this._eElementsTypes.EET_VALUE, value);
  }

  _moneyFormat(value) {
    return format(value, window.OPWrapperService.currencyInfo.decimals)
  }

  _getMarkup() {
    return `<div class="ui-container__win_line_label__image_list" id=${this.interactiveElementsIds[this._eElementsTypes.EET_IMAGE_LIST]}>
            </div>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
              0.12341234 х 3 = 0.12341234
            </span>`
  }
}
