import gameConfig from '../../assets/config.json';
import '../../assets/exported/UIWinLine/UIWinLineAssets.css';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import MyriadPro from '../../assets/fonts/myriadpro/MYRIADPRO-BOLD.OTF';
import RevansMedium from '../../assets/fonts/revans/Revans-Medium.ttf';
import eSoundType from '../sounds/eSoundType';

import flashlib from '../../assets/exported/game/FlashLib.json';

// import assets from '../../assets/exported/game/FlashLibAssets';
import eAnimationTypes from '../enums/eAnimationTypes';

const assets = 'flashlib/game/AssetsCombined.json'

const config = {
  assets: [
    { name: 'GameAssets', path: assets, extension: 'json' },
    { name: 'GameFlashLib', path: flashlib, extension: 'json' },
    { name: 'gameConfig', path: gameConfig, extension: 'json' },
    { name: eAnimationTypes.EAT_SYMBOLS, path: 'animations/symbols/symbols_hot_and_spicy_optimazed1.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BIG_WIN, path: 'animations/bigWins/DWB_b_o_e1.json', extension: 'json' },
    { name: eAnimationTypes.SYMBOL_FRAME_AND_FIRE, path: 'animations/symbolFrameAndFire/skeleton_optimazed1.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BACKGROUND, path: 'animations/background/bg_animation_hot_and_spicy1.json', extension: 'json' },
  ],
  fonts: [
    { name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'Myriad Pro',
      path: MyriadPro,
    },{
      name: 'RevansMedium',
      path: RevansMedium,
    },
  ],
  sounds: eSoundType
}

export default config;
