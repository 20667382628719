import BaseController from './baseSystemController';
import ScaleManager from '../../../scaleManager/ScaleManager';
import eControllerTypes from './bugReport/eControllerTypes';
import ControllerReportForm from './bugReport/ControllerReportForm';
import SubmitSuccess from './components/submitSuccess';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerBugReport extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._defaultSizes = {
      H: {
        width: 2340,
        height: 1080,
      },
      V: {
        width: 1080,
        height: 2340
      }
    };

    this._eButtonsTypes = {
      EBT_CLOSE: 'close_button',
    };
    this._eEventTypes = {
      CLOSE_CLICK: this.getEventName(this._eButtonsTypes.EBT_CLOSE),
    };
    this._eControllersClasses = {
      [this.controllerTypes.EBRCT_FORM]: ControllerReportForm,
      [this.controllerTypes.EBRCT_SUBMIT_SUCCESS]: SubmitSuccess,
    }
    this._eElementsTypes = {
      EET_OVERLAY: 'overlay',
      EET_MODAL_CONTAINER: 'modal_container',
      EET_CLOSE_BUTTON: this._eButtonsTypes.EBT_CLOSE,
      EET_CONTAINER_FORM: this.controllerTypes.EBRCT_FORM,
      EET_CONTAINER_SUBMIT_SUCCESS: this.controllerTypes.EBRCT_SUBMIT_SUCCESS,
    };

    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: this.hide.bind(this),
    };

    this._isSpinBlocker = true;

    this.init(container);
    this.hide();
  }

  show() {
    super.show();
    const controllerForm = this.controllers[this.controllerTypes.EBRCT_FORM];
    const controllerSubmitSuccess = this.controllers[this.controllerTypes.EBRCT_SUBMIT_SUCCESS];
    controllerForm.show();
    controllerSubmitSuccess.hide();
  }

  cleanup() {
    super.cleanup();
    this._abortRequest && this._abortRequest();
  }

  _afterInit() {
    super._afterInit();
    this._overlay = this.interactiveElements[this._eElementsTypes.EET_OVERLAY];
    this._modalContaier = this.interactiveElements[this._eElementsTypes.EET_MODAL_CONTAINER];
    this._overlay.onclick = (e) => {
      if (e.target === this._overlay) this.hide();
    };

    const controllerSubmitSuccess = this.controllers[this.controllerTypes.EBRCT_SUBMIT_SUCCESS];
    controllerSubmitSuccess.localizations = {
      [controllerSubmitSuccess.elementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.submitSuccess.title`,
      [controllerSubmitSuccess.elementsTypes.EET_TEXT]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.submitSuccess.description`,
      [controllerSubmitSuccess.elementsTypes.EET_BUTTON_TEXT]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.submitSuccess.buttonBackToMenu`,
    }
  }

  _addExtraControllersListeners() {
    super._addExtraControllersListeners();
    const controllerForm = this.controllers[this.controllerTypes.EBRCT_FORM];
    const controllerSubmitSuccess = this.controllers[this.controllerTypes.EBRCT_SUBMIT_SUCCESS];

    controllerForm.on(controllerForm.events[this.controllerTypes.EBRCT_FORM].SUBMIT, async (data) => {
      // show spinner
      // request here
      console.log(data);
      const { abortRequest, promise } = window.OPWrapperService.serverManager.createBugReport(data);
      this._abortRequest = abortRequest;
      // this._showSpinner();
      const success = await promise;
      // this._hideSpinner();

      if (success) {
        controllerForm.hide();
        controllerSubmitSuccess.show();
      }
    });

    controllerSubmitSuccess.on(controllerSubmitSuccess.events[this.controllerTypes.EBRCT_SUBMIT_SUCCESS].OK_CLICK, () => {
      this.hide();
    })
  }

  get controllerTypes() {
    return eControllerTypes;
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    if (!this._mounted) return;

    const orientation = this._scaleData.orientation;
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    this._modalContaier.dataset.orientation = scaleData.isPortrait ? 'portrait' : 'landscape';
    this._modalContaier.dataset.deviceType = scaleData.isMobile ? 'mobile' : 'desktop';

    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this._overlay,
      relativeScale: uiScale,
      defaultWidth: this._scaleData.innerWidth / uiScale + 2,
      defaultHeight: this._scaleData.innerHeight / uiScale,
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OVERLAY]} class="system-ui-container__modal_wrapper system-ui-container__modal_wrapper__bug_report">
      <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_MODAL_CONTAINER]} class="system-ui-container__modal_container system-ui-container__modal__bug_report">
        <div class="system-ui-container__modal__bug_report__header">
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_CLOSE_BUTTON]} class="system-ui-container__modal__bug_report__header__close_button">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42 14L14 42M14 14L42 42" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <div class="system-ui-container__modal__bug_report__container_form" id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_FORM]}>
        </div>
        <div class="system-ui-container__modal__bug_report__container_success" id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_SUBMIT_SUCCESS]}>
        </div>
      </div>
    </div>`
  }
}
