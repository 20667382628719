import BaseUi from '../baseUi';
import PlayerUIInterface from './playerUIInterface';
import eControllerTypes from '../../controllers/player/eControllerTypes';
import {
  ControllerAutoPlayButton, ControllerBalance, ControllerBet, ControllerBetSelect, ControllerBetSelectorTable,
  ControllerInfo, ControllerMenu, ControllerSound, ControllerSpin,
  ControllerTotalWin, ControllerTournamentsLabel, ControllerWin, ControllerTurboPlay, ControllerLabel, ControllerClock,
  ControllerInfoPanel, ControllerAutoPlayCount, ControllerWinLineLabel, ControllerFreespinsCount,
} from '../../controllers';
import ControllerAutoPlayTable from '../../controllers/player/ControllerAutoPlayTable';

export default class BasePlayerUi extends BaseUi {
  constructor(props) {
    super(props);

    this._config = {
      init_controls: true,
      ...this._config,
    };

    this._eControllersClasses = {
      [this.controllerTypes.ECT_BALANCE]: ControllerBalance,
      [this.controllerTypes.ECT_BET]: ControllerBet,
      [this.controllerTypes.ECT_WIN]: ControllerWin,
      [this.controllerTypes.ECT_INFO]: ControllerInfo,
      [this.controllerTypes.ECT_MENU]: ControllerMenu,
      // [this.controllerTypes.ECT_FULL_SCREEN]: ControllerFullScreen,
      [this.controllerTypes.ECT_TOTAL_WIN]: ControllerTotalWin,
      [this.controllerTypes.ECT_SPIN]: ControllerSpin,
      [this.controllerTypes.ECT_AUTO_SPIN]: ControllerAutoPlayButton,
      [this.controllerTypes.ECT_TURBO_SPIN]: ControllerTurboPlay,
      [this.controllerTypes.ECT_LABEL]: ControllerLabel,
      [this.controllerTypes.ECT_SOUND]: ControllerSound,
      [this.controllerTypes.ECT_BET_SELECT]: ControllerBetSelect,
      [this.controllerTypes.ECT_BET_SELECTOR_TABLE]: ControllerBetSelectorTable,
      [this.controllerTypes.ECT_TOURNAMENTS_LABEL]: ControllerTournamentsLabel,
      [this.controllerTypes.ECT_INFO_PANEL]: ControllerInfoPanel,
      [this.controllerTypes.ECT_INFO_PANEL_CLOCK]: ControllerClock,
      [this.controllerTypes.ECT_AUTO_SPIN_COUNT]: ControllerAutoPlayCount,
      [this.controllerTypes.ECT_WIN_LINE_LABEL]: ControllerWinLineLabel,
      [this.controllerTypes.ECT_AUTO_PLAY_TABLE]: ControllerAutoPlayTable,
      [this.controllerTypes.ECT_FREESPINS_COUNT]: ControllerFreespinsCount,
    };
    this._constantControllerTypes = new Set([this.controllerTypes.ECT_TOURNAMENTS_LABEL]);
  }

  _checkConfig() {
    if (!this._config.init_controls) {

      const types = Object.values(this.controllerTypes);
      for (const type of types) {
        if (!this._constantControllerTypes.has(type)) this._excludedControllerTypes.add(type)
      }

      this._getMarkup = this._getConstantMarkup;
      return;
    }

    if (this._partnerConfig.disableAutoplay) {
      this._excludedControllerTypes.add(this.controllerTypes.ECT_AUTO_SPIN)
      this._excludedControllerTypes.add(this.controllerTypes.ECT_TURBO_SPIN)
    }
  }

  _addExtraControllersListeners() {
    const betsSelectController = this.controllers[this.controllerTypes.ECT_BET_SELECT];
    const spinController = this.controllers[this.controllerTypes.ECT_SPIN];
    const autoSpinController = this.controllers[this.controllerTypes.ECT_AUTO_SPIN];
    const betsTableController = this.controllers[this.controllerTypes.ECT_BET_SELECTOR_TABLE];
    const autoPlayTableController = this.controllers[this.controllerTypes.ECT_AUTO_PLAY_TABLE];
    const tournamentsLabelController = this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL];
    // const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
    const events = this.events;

    if (betsSelectController) {
      betsSelectController.on(events[this.controllerTypes.ECT_BET_SELECT].SHOW_BETS_CLICK, () => {
        if (betsTableController) betsTableController.show();
        if (autoSpinController && autoPlayTableController) {
          autoPlayTableController.hide();
          autoSpinController.toggleActive(false);
        }
      });

      betsSelectController.on(events[this.controllerTypes.ECT_BET_SELECT].HIDE_BETS_CLICK, () => {
        if (betsTableController) betsTableController.hide();
      });
    }
    if (autoSpinController && autoPlayTableController) {
      autoSpinController.on(events[this.controllerTypes.ECT_AUTO_SPIN].AUTO_SPIN_CLICK, () => {
        if (window.OPWrapperService.autoPlay.active) {
          window.OPWrapperService.autoPlay.active = false;
          return;
        }

        const visible = autoPlayTableController.toggle();
        autoSpinController.toggleActive(visible);
        if (betsTableController && visible) {
          betsSelectController.reset();
          betsTableController.hide();
        }
      });

      autoSpinController.toggleActive(window.OPWrapperService.autoPlay.active);
      window.OPWrapperService.autoPlay.onChange.add(() => {
        autoSpinController.toggleActive(window.OPWrapperService.autoPlay.active);
      })
    }
  }

  _afterControllersInit() {
    this._addExtraControllersListeners();

    if (!window.OPWrapperService.model.data.tournamentsInfo || !window.OPWrapperService.model.data.tournamentsInfo.active.length) {
      this.controllers[this.controllerTypes.ECT_TOURNAMENTS_LABEL].hide();
    }
    this._topMainTextsControllers = [
      this.controllers[this.controllerTypes.ECT_LABEL],
      this.controllers[this.controllerTypes.ECT_WIN],
    ]
    this._bottomMainTextsControllers = [
      this.controllers[this.controllerTypes.ECT_TOTAL_WIN],
      this.controllers[this.controllerTypes.ECT_AUTO_SPIN_COUNT],
      this.controllers[this.controllerTypes.ECT_WIN_LINE_LABEL],
    ]
  }

  _initInterface() {
    return new PlayerUIInterface(this);
  }

  _setupRoot() {
    this.root.setAttribute('id', 'root-ui');
    this.root.classList.add('ui-container');

    this.safeZone = document.createElement('div');
    this.safeZone.classList.add('ui-container__safe_zone');
    this.root.appendChild(this.safeZone);
  }

  _createInterfaceContainer() {
    const interfaceContainer = document.createElement('div');
    interfaceContainer.setAttribute('id', 'player-interface-container');
    this.safeZone.append(interfaceContainer);
    return interfaceContainer;
  }

  get topMainTextsControllers() {
    return this._topMainTextsControllers;
  }

  get bottomMainTextsControllers() {
    return this._bottomMainTextsControllers;
  }

  get controllersIdPrefix() {
    return 'player_ui';
  }

  get controllerTypes() {
    return eControllerTypes;
  }

  set scaleData(data) {
    const ScaleManager = window.OPWrapperService.ScaleManager;
    this._scaleData = data;
    const uiScale = this._scaleData.scale;

    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    //repeating the behavior of the canvas and its stage
    const marginX = ScaleManager.safeZone.left + this._scaleData.stageX / uiScale;
    const marginY = ScaleManager.safeZone.top + this._scaleData.stageY / uiScale;

    this.safeZone.style.marginRight = `${marginX}px`;
    this.safeZone.style.marginLeft = `${marginX}px`;
    this.safeZone.style.marginTop = `${marginY}px`;
    this.safeZone.style.marginBottom = `${marginY}px`;
    this.safeZone.style.height = `${ScaleManager.safeZone.height}px`;

    window.OPWrapperService.ScaleManager.makeScalingHTMLElement({
      HTMLElement: this.root,
      relativeScale: uiScale,
      defaultWidth: this._scaleData.innerWidth / uiScale,
      defaultHeight: this._scaleData.innerHeight / uiScale
    })
  }
}
